// config.js


const config = {
  projectId: 'plazit',
  domain: 'https://plazit.tangoai.co',
  
  sidebarPages: {
    admin: ['Dashboard', 'Reports', 'Playground', 'Optimization'],
    user: ['Dashboard']
  },
  
  exemptedColumns: [
    'Invoice_Number', 
    'Customer_UID', 
    'invoice date', 
    'shipping date', 
    'year', 
    'YEAR', 
    'Year', 
    'Yealry%', 
    'Month', 
    'MONTH'
  ]
};

export default config;